import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { HTTPGET } from "@/api";

Vue.use(VueRouter);

const routes = [
  {
    path: "/cdec",
    name: "Cdec",
    meta: {
      title: "сдэк",
      auth: false
    },
    component: () => import("@/views/cdec/Cdec.vue")
  },
  {
    path: "/home",
    name: "Home",
    meta: {
      layout: "main",
      auth: true,
      title: "главная",
      parentPath: "/home",
      whereIs: "на главной"
    },
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/tk",
    name: "TK",
    meta: {
      layout: "main",
      auth: true,
      title: "отправки",
      parentPath: "/home",
      whereIs: "в отправках"
    },
    component: () => import("@/components/homeContent/TK.vue")
  },
  {
    path: "/repairs",
    name: "Repairs",
    meta: { layout: "main", auth: true, title: "ремонты" },
    component: () => import("@/views/repairs/Repairs.vue"),
    children: [
      {
        path: "/repairs/repairs-list",
        name: "RepairsList",
        meta: {
          layout: "main",
          auth: true,
          title: "список ремонтов",
          parentPath: "/repairs/repairs-list",
          whereIs: "в созданных ремонтах"
        },
        component: () => import("@/views/repairs/RepairsList.vue")
      },
      {
        path: "/repairs/acts/acts-list",
        name: "ActsList",
        meta: {
          layout: "main",
          auth: true,
          title: "отказы от ремонта",
          parentPath: "/repairs/repairs-list",
          whereIs: "в актах"
        },
        component: () => import("@/views/repairs/acts/ActsList.vue")
      },
      {
        path: "/repairs/diagnostics/diagnostics-list",
        name: "DiagnosticsList",
        meta: {
          layout: "main",
          auth: true,
          title: "ремонты без зч и диагностики",
          parentPath: "/repairs/repairs-list",
          whereIs: "в диагностиках"
        },
        component: () =>
          import("@/views/repairs/diagnostics/DiagnosticsList.vue")
      },
      {
        path: "/repairs/repairs-pending-issue-list",
        name: "RepairsPendingIssueList",
        meta: {
          layout: "main",
          auth: true,
          title: "ремонты ожидающие выдачи",
          parentPath: "/repairs/repairs-list",
          whereIs: "в ремонтах ожидающих выдачи"
        },
        component: () => import("@/views/repairs/RepairsPendingIssueList.vue")
      },
      {
        path: "/repairs/repairs-list-all",
        name: "RepairsListAll",
        meta: {
          layout: "main",
          auth: true,
          title: "все ремонты",
          parentPath: "/repairs/repairs-list",
          whereIs: "во всех ремотах"
        },
        component: () => import("@/views/repairs/RepairsListAll.vue")
      },
      {
        path: "/repairs/favorites",
        name: "FavoritesRepairs",
        meta: {
          layout: "main",
          auth: true,
          title: "избранные ремонты",
          parentPath: "/repairs/repairs-list",
          whereIs: "в избранных ремонтах"
        },
        component: () => import("@/views/repairs/FavoritesRepairs.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.USER_INFO.status === "admin") {
            store.dispatch("PATH_FROM", from.path);
            store.dispatch("PATH_TO", to.path);
            next();
          } else {
            next("/home");
          }
        }
      },
      {
        path: "/repairs/consultation",
        name: "ConsultationRepairs",
        meta: {
          layout: "main",
          auth: true,
          title: "сонсультация с мастерской",
          parentPath: "/repairs/repairs-list",
          whereIs: "в избранных ремонтах"
        },
        component: () => import("@/views/repairs/ConsultationRepairs.vue"),
        beforeEnter: (to, from, next) => {
          if (store.getters.USER_INFO.status === "admin") {
            store.dispatch("PATH_FROM", from.path);
            store.dispatch("PATH_TO", to.path);
            next();
          } else {
            next("/home");
          }
        }
      }
    ]
  },
  {
    path: "/repairs/add-new-repair",
    name: "AddNewRepair",
    meta: {
      layout: "main",
      auth: true,
      title: "новый ремонт",
      parentPath: "/repairs/repairs-list",
      whereIs: "добавляет новый ремонт"
    },
    component: () => import("@/views/repairs/AddNewRepair.vue")
  },
  {
    path: "/repairs/repair/:id",
    name: "OneRepair",
    meta: {
      layout: "main",
      auth: true,
      title: "ремонт",
      whereIs: "в каком-то ремонте"
    },
    component: () => import("@/views/repairs/OneRepair.vue"),
    props: true
  },
  {
    path: "/repairs/repairs-orders",
    name: "RepairsOrders",
    meta: {
      layout: "main",
      auth: true,
      parentPath: "/repairs/repairs-orders"
    },
    component: () => import("@/views/repairs/repairsOrders/RepairsOrders.vue"),
    children: [
      {
        path: "/repairs/repairs-orders-list/:status",
        name: "RepairsOrdersList",
        meta: {
          layout: "main",
          auth: true,
          title: "список заявок",
          parentPath: "/repairs/repairs-orders",
          whereIs: "в заявках"
        },
        component: () =>
          import("@/views/repairs/repairsOrders/RepairsOrdersList.vue"),
        props: true
      }
    ]
  },
  {
    path: "/repairs/new-repairs-order",
    name: "NewRepairsOrders",
    meta: {
      layout: "main",
      auth: true,
      title: "новая заявка",
      parentPath: "/repairs/repairs-orders",
      whereIs: "создаёт новую заявку"
    },
    component: () =>
      import("@/views/repairs/repairsOrders/NewRepairsOrders.vue")
  },
  {
    path: "/repairs/order/:id",
    name: "OneOrder",
    meta: {
      layout: "main",
      auth: true,
      title: "заявка",
      parentPath: "/repairs/repairs-orders",
      whereIs: "в какой-то заявке"
    },
    component: () => import("@/views/repairs/repairsOrders/OneOrder.vue"),
    props: true
  },
  {
    path: "/zap",
    name: "Zap",
    meta: {
      layout: "main",
      auth: true,
      title: "запчасти",
      parentPath: "/zap/order-zap-list"
    },
    component: () => import("@/views/zap/Zap.vue"),
    props: true,
    children: [
      {
        path: "/zap/add-new-order-zap",
        name: "AddNewOrderZap",
        meta: {
          layout: "main",
          auth: true,
          title: "новая заявка на запчасти",
          parentPath: "/zap/order-zap-list",
          whereIs: "создаёт заказ на запчасти"
        },
        component: () => import("@/views/zap/AddNewOrderZap.vue"),
        props: true
      },
      {
        path: "/zap/order-zap-list",
        name: "OrderZapList",
        meta: {
          layout: "main",
          auth: true,
          title: "список заявок на запчасти",
          parentPath: "/zap/order-zap-list",
          whereIs: "просматривает список заказов на запчасти"
        },
        component: () => import("@/views/zap/OrderZapList.vue"),
        props: true
      }
    ]
  },
  {
    path: "/zap/order/:id",
    name: "OneOrderZap",
    meta: {
      layout: "main",
      auth: true,
      title: "заказ",
      parentPath: "/zap/order-zap-list",
      whereIs: "в каком-то заказе на запчасти"
    },
    component: () => import("@/views/zap/OneOrderZap.vue"),
    props: true
  },
  {
    path: "/reports",
    name: "Reports",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "гарантийные отчеты",
      parentPath: "/reports/repair-check-list"
    },
    component: () => import("@/views/reports/Reports.vue"),
    children: [
      {
        path: "/reports/repair-check-list",
        name: "RepairCheckList",
        meta: {
          layout: "main",
          auth: true,
          reports: true,
          title: "отчеты",
          parentPath: "/reports/repair-check-list",
          whereIs: "в отчетах"
        },
        component: () => import("@/views/reports/RepairCheckList.vue"),
        props: true
      },
      {
        path: "/reports/reports-list",
        name: "ReportsList",
        meta: {
          layout: "main",
          auth: true,
          reports: true,
          title: "отчеты",
          parentPath: "/reports/repair-check-list",
          whereIs: "в отчетах"
        },
        component: () => import("@/views/reports/ReportsList.vue"),
        props: true
      }
    ]
  },
  {
    path: "/reports/report/:id",
    name: "OneReport",
    meta: {
      layout: "main",
      auth: true,
      title: "отчет",
      parentPath: "/reports/repair-check-list",
      whereIs: "в каком-то отчете"
    },
    component: () => import("@/views/reports/OneReport.vue"),
    props: true
  },
  {
    path: "/kabinet",
    name: "Kabinet",
    meta: {
      layout: "main",
      auth: true,
      title: "личный кабинет",
      whereIs: "в реквизитах"
    },
    component: () => import("@/views/kabinet/Kabinet.vue")
  },
  {
    path: "/kabinet/close-site",
    name: "CloseSite",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "доступ на сайт",
      whereIs: "хз где"
    },
    component: () => import("@/views/kabinet/admin/CloseSite.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.gruppa === "super") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/admin/file-manager",
    name: "FileManager",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "менеджер файлов",
      whereIs: "хз где"
    },
    component: () => import("@/views/kabinet/admin/file-manager/"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.gruppa === "super") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/users",
    name: "Users",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "пользователи",
      whereIs: "в пользователях"
    },
    component: () => import("@/views/kabinet/admin/Users.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/blank",
    name: "UploadBlank",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "загрузка бланка",
      whereIs: "загружает бланк"
    },
    component: () => import("@/views/kabinet/admin/UploadBlank.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/users/user/:id",
    name: "UserInfo",
    meta: {
      layout: "main",
      auth: true,
      reports: false,
      title: "пользователи",
      whereIs: "в каком-то пользователе"
    },
    component: () => import("@/views/kabinet/admin/UserInfo.vue"),
    props: true
  },
  {
    path: "/kabinet/users/add-new-user",
    name: "AddNewUser",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "создание пользователя",
      whereIs: "создаёт нового пользователя"
    },
    component: () => import("@/views/kabinet/admin/AddNewUser.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/upload-instr",
    name: "UploadInstr",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "инструмент",
      whereIs: "в инструменте"
    },
    component: () => import("@/views/kabinet/admin/UploadInstr.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/stats",
    name: "Stats",
    meta: {
      layout: store.getters.USER_INFO.status === "admin" ? "main" : null,
      auth: true,
      reports: true,
      title: "статистика",
      whereIs: "в статистике"
    },
    component: () => import("@/views/kabinet/stats/Stats.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.USER_INFO.status === "admin" ||
        store.getters.USER_INFO.status === "tehnik"
      ) {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/stats-sc",
    name: "StatsSc",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "статистика по СЦ",
      whereIs: "в статистике по сц"
    },
    component: () => import("@/views/kabinet/stats/StatsSc.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/stats/one-instr",
    name: "OneInstr",
    meta: {
      layout: store.getters.USER_INFO.status === "admin" ? "main" : null,
      auth: true,
      reports: true,
      title: "статистика",
      whereIs: "в статистике"
    },
    component: () => import("@/views/kabinet/stats/OneInstr.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (
        store.getters.USER_INFO.status === "admin" ||
        store.getters.USER_INFO.status === "tehnik"
      ) {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/upload-realization",
    name: "UploadRealization",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "загруженные реализации",
      whereIs: "смотрит загруженные реализации"
    },
    component: () => import("@/views/kabinet/admin/UploadRealization.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/not-cod-in-sheme",
    name: "NotCodInSheme",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "коды",
      whereIs: "в кодах"
    },
    component: () => import("@/views/kabinet/admin/NotCodInSheme.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/search-zap-in-repairs",
    name: "SearchZapInRepairs",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "поиск запчастей в ремонтах",
      whereIs: "в поиске запчастей в ремонтах"
    },
    component: () => import("@/views/kabinet/admin/SearchZapInRepairs.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/otpravki",
    name: "Otpravki",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "отправки",
      whereIs: "в отправках"
    },
    component: () => import("@/views/kabinet/admin/Otpravki.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/menedger-stats",
    name: "MenedgerStats",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "статистика по менеджерам",
      whereIs: "в статистике по менеджерам"
    },
    component: () => import("@/views/kabinet/admin/MenedgerStats.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/kabinet/list-sc",
    name: "ListScFromKalibrcompany",
    meta: {
      layout: "main",
      auth: true,
      reports: true,
      title: "список СЦ",
      whereIs: "просматривает список СЦ"
    },
    component: () =>
      import("@/views/kabinet/admin/ListScFromKalibrcompany.vue"),
    props: true,
    beforeEnter: (to, from, next) => {
      if (store.getters.USER_INFO.status === "admin") {
        store.dispatch("PATH_FROM", from.path);
        store.dispatch("PATH_TO", to.path);
        next();
      } else {
        next("/home");
      }
    }
  },
  {
    path: "/login",
    name: "Login",
    meta: { layout: "empty", title: "авторизация", whereIs: "авторизуется" },
    component: () => import("@/views/Login.vue")
  },
  { path: "/", redirect: "/home" },
  {
    path: "*",
    name: "NotFound",
    meta: { title: "упс..." },
    component: () => import("@/views/NotFound.vue")
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

//router.beforeEach(async (to, _from, next) => {
//  const requireAuth = to.matched.some(record => record.meta.reports);
//  if (!store.state.userInfo.reports && requireAuth) {
//    next("/home");
//  } else {
//    next();
//  }
//});
function userUrlVisit(url) {
  HTTPGET({
    url: "/template/useractionhistory/add_user_url_visit.php",
    params: {
      url
    }
  });
}
router.beforeEach((to, _from, next) => {
  store.dispatch("CHECK_AUTH");
  document.title = to.meta.title;
  const requireAuth = to.matched.some(record => record.meta.auth);
  if (!store.getters.getAuth && requireAuth) {
    store.dispatch("PATH_TO", to.path);
    next("/login");
  } else {
    store.dispatch("PATH_FROM", _from.path);
    store.dispatch("PATH_TO", to.path);
    userUrlVisit(to.meta.whereIs || to.meta.title);
    if (to.meta.reports && !store.getters.USER_INFO.reports) {
      next("/error");
    } else {
      next();
    }
  }
});

export default router;

//if (store.getters.USER_INFO.status === "tehnik") {
//  console.log(4);
//  next("/kabinet/stats");
//}
