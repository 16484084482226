<template>
  <div>
    <nav
      id="navbar"
      class="navbar sticky-top navbar-expand-lg navbar-dark bg-primary"
    >
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <li class="nav-item" @click="addActive('/home')" name="/home">
            <router-link class="nav-link" to="/home">Главная</router-link>
          </li>
          <li
            class="nav-item"
            @click="addActive('/repairs/repairs-list')"
            name="/repairs/repairs-list"
          >
            <router-link class="nav-link" to="/repairs/repairs-list"
              >Ремонты
              <span
                class="badge badge-danger check-repairs"
                v-if="USER_INFO.status === 'admin'"
                >{{ ALL_COUNT }}</span
              >
            </router-link>
          </li>
          <li>
            <router-link
              v-if="USER_INFO.status !== 'admin'"
              class="nav-link"
              to="/repairs/repairs-pending-issue-list"
            >
              <span class="badge badge-danger check-repairs">{{
                REPAIRS_PENDING_ISSUE_COUNT
              }}</span></router-link
            >
          </li>
          <li
            class="nav-item"
            @click="addActive('/repairs/repairs-orders')"
            name="/repairs/repairs-orders"
          >
            <router-link
              class="nav-link"
              to="/repairs/repairs-orders-list/not-finished"
              >Заявки по ремонтам</router-link
            >
          </li>
          <li
            class="nav-item"
            @click="addActive('/zap/order-zap-list')"
            name="/zap/order-zap-list"
          >
            <router-link class="nav-link" to="/zap/order-zap-list?type=inwork"
              >Запчасти</router-link
            >
          </li>
          <li
            v-if="REPORTS"
            class="nav-item"
            @click="addActive('/reports/repair-check-list')"
            name="/reports/repair-check-list"
          >
            <router-link
              class="nav-link"
              to="/reports/repair-check-list?check=false"
              >Гарантийные отчеты</router-link
            >
          </li>
          <li class="nav-item" @click="addActive('/kabinet')" name="/kabinet">
            <router-link class="nav-link" to="/kabinet">{{
              USER_NAME
            }}</router-link>
          </li>
          <li v-if="USER_INFO.gruppa === 'super'" class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Dropdown
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a class="dropdown-item" href="#">Action</a>
              <a class="dropdown-item" href="#">Another action</a>
              <div class="dropdown-divider"></div>
              <a class="dropdown-item" href="#">Something else here</a>
            </div>
          </li>
        </ul>
        <span
          class="badge badge-danger check-repairs"
          v-if="USER_INFO.status === 'admin'"
          >{{ GET_CHECK_REPAIRS }}</span
        >
        <button
          type="button"
          class="btn btn-sm btn-warning shadow-none btn-not"
          @click="visibleNotification"
        >
          {{ title }}
          <span class="badge badge-light">{{ NOTIFICATIONSLIST.length }}</span>
        </button>
        <Logout />
      </div>
    </nav>
    <Notifications />
    <router-view />
  </div>
</template>
<script>
import Logout from "@/components/Logout";
import { mapGetters, mapMutations, mapActions } from "vuex";
import Notifications from "@/components/Notifications";
export default {
  name: "main-layout",
  data: () => ({
    title: "сообщения",
    interval: null
  }),
  watch: {
    $route: function() {
      if (this.$route.meta.parentPath) {
        this.addActive(this.$route.meta.parentPath);
      }
    },
    auth() {
      if (!this.getAuth) {
        clearInterval(this.interval);
      }
    }
  },
  mounted() {
    if (this.$route.meta.parentPath) {
      this.addActive(this.$route.meta.parentPath);
    }
    this.GET_REPAIRS_COUNTS();
    this.interval = setInterval(() => {
      this.GET_REPAIRS_COUNTS();
    }, 10000);
  },
  computed: {
    ...mapGetters([
      "REPORTS",
      "USER_NAME",
      "VISIBLENOTIFICATION",
      "NOTIFICATIONSLIST",
      "USER_INFO",
      "GET_CHECK_REPAIRS",
      "ALL_COUNT",
      "REPAIRS_PENDING_ISSUE_COUNT",
      "getAuth"
    ]),
    auth() {
      return this.getAuth;
    }
  },
  components: {
    Logout,
    Notifications
  },
  methods: {
    ...mapActions(["GET_REPAIRS_COUNTS"]),
    ...mapMutations(["set_visible_notifications"]),
    addActive(el) {
      let elem = document.getElementsByClassName("nav-item");
      elem = Array.from(elem);
      elem.map(item => {
        item.classList.remove("active");
      });
      document.getElementsByName(el)[0].classList.add("active");
      document
        .getElementById("navbarSupportedContent")
        .classList.remove("show");
    },
    visibleNotification() {
      this.set_visible_notifications(!this.VISIBLENOTIFICATION);
    }
  },
  destroyed() {
    clearInterval(this.interval);
  }
};
</script>
<style scoped>
.navbar-dark .navbar-nav .nav-link:focus,
.navbar-dark .navbar-nav .nav-link:hover {
  color: white;
}
.btn-not {
  margin-right: 10px;
}
.check-repairs {
  margin: 0 10px;
}
</style>
