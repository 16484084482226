export const ordersListModule = {
  state: () => ({
    search: "",
    year: new Date().getFullYear(),
    count: "100"
  }),
  mutations: {
    set_search_text(state, data) {
      state.search = data;
    },
    set_year_order_list_filter(state, data) {
      state.year = data;
    },
    set_count_order_list_filter(state, data) {
      state.count = data;
    }
  },
  actions: {},
  getters: {
    ORDERSLISTSEARCHTEXT: state => {
      return state.search;
    },

    ORDERSLISTYEAR: state => {
      return state.year;
    },
    ORDERSLISTCOUNT: state => {
      return state.count;
    }
  }
};
