export const repairsListAllModule = {
  state: () => ({
    limit: 100,
    filter: [
      { field: "repairs.repair_number", value: "" },
      { field: "repairs.receiptNumber", value: "" },
      { field: "users.user", value: "", admin: true },
      { field: "repairs.date_add", value: "", placeholder: "ГГГГ-ММ-ДД" },
      { field: "instr.name_instr", value: "" },
      { field: "repairs.serialNumberGroup", value: "" },
      { field: "repairs.serialNumber", value: "" },
      { field: "repairs.dateSale", value: "", placeholder: "ГГГГ-ММ-ДД" },
      { field: "repairs.dateRepair", value: "", placeholder: "ГГГГ-ММ-ДД" },
      { field: "repairs.defect", value: "" },
      { field: "repairs.seller", value: "" },
      {
        field: "repairs.typeOfRepair",
        value: "all",
        select: true,
        options: [
          { field: "repair", value: "ремонт" },
          { field: "noRepair", value: "акт" },
          { field: "diagnostiks", value: "диагностика" },
          { field: "all", value: "все" }
        ]
      }
    ],
    scroll: 0
  }),
  mutations: {
    set_repairs_list_scroll(state, data) {
      state.scroll = data;
    },
    set_limit(state, data) {
      state.limit = data;
    },
    set_item_filter(state) {
      state.filter = state.filter.filter(item => item.field !== "users.user");
    },
    set_item_filter_admin(state) {
      let isuser = null;
      state.filter.map(item => {
        if (item.field === "users.user") {
          isuser = 1;
        }
      });
      if (!isuser) {
        state.filter.splice(2, 0, {
          field: "users.user",
          value: "",
          admin: true
        });
      }
    }
  },
  actions: {},
  getters: {
    REPAIRSLISTALLFILTER: state => {
      return state.filter;
    },
    REPAIRSLISTALLLIMIT: state => {
      return state.limit;
    },
    REPAIRS_LIST_SCROLL: state => {
      return state.scroll;
    }
  }
};
