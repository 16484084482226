//const curent_year = new Date().getFullYear();
export const reportsDateModule = {
  state: () => ({
    date: {
      mountOrQuarter: "month",
      month: new Date().getMonth(),
      quarter: 1,
      year: new Date().getFullYear(),
      year1: new Date().getFullYear() - 1,
      year2: new Date().getFullYear(),
      user: "all",
      curent_year: new Date().getFullYear(),
      year_for_reports_list_filter: new Date().getFullYear()
    }
  }),
  mutations: {
    set_quarter(state, data) {
      state.quarter = data;
    },
    set_year2(state, data) {
      state.year2 = data;
    },
    set_month(state, data) {
      state.month = data;
    },
    set_user(state, data) {
      state.user = data;
    },
    set_year_for_reports_list_filter(state, year) {
      state.date.year_for_reports_list_filter = year;
    }
  },
  actions: {},
  getters: {
    REPORTSDATE: state => {
      return state.date;
    },
    YEAR_FOR_REPORTS_LIST_FILTER: state => {
      return state.date.year_for_reports_list_filter;
    }
  }
};
