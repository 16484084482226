export const pathModule = {
  state: () => ({
    path: [],
    pathRepair: []
  }),
  mutations: {
    addPath(state, path) {
      state.path = [];
      state.pathRepair = [];
      state.path.push(path);
    },
    pushPath(state, path) {
      state.pathRepair = [];
      state.pathRepair.push(" / " + path);
    }
  },
  actions: {
    ADDPATH({ commit }, path) {
      commit("addPath", path);
    },
    PUSHPATH({ commit }, path) {
      commit("pushPath", path);
    }
  },
  getters: {
    PATH: state => {
      return state.path + state.pathRepair;
    }
  }
};
