<template>
  <div
    v-if="VISIBLENOTIFICATION && NOTIFICATIONSLIST.length"
    class="notifications col-md-4"
  >
    <div class="item" v-for="(item, idx) in NOTIFICATIONSLIST" :key="idx">
      <div class="sender">
        <div :title="`сообщение от ${item.user} ${item.data}`">
          <div>
            сообщение от <span class="user">{{ item.user }}</span>
          </div>
          <div>{{ item.data }}</div>
        </div>
        <div>
          <a
            v-if="visibleHrefGo"
            :href="item.href"
            @click.prevent="
              goRepair(
                item.type,
                item.typeOfRepair,
                item.confirm_act_guid,
                item.href,
                item.id
              )
            "
            >перейти -> {{ item.type }} №{{
              item.repair_number ||
                item.orderId ||
                item.order_zap_id ||
                `${item.reportId} с ${item.date_report_start} по ${item.date_report_end}`
            }}</a
          >
        </div>
      </div>
      <div class="message">{{ item.message }}</div>
      <button
        class="btn btn-sm btn-light btn-read shadow-none"
        @click="read(item.id, item.type)"
      >
        прочитать
      </button>
    </div>
    <div class="close-not text-center">
      <a href="#" @click.prevent="close">скрыть все сообщения</a>
    </div>
  </div>
</template>
<script>
import { HTTPGET, HTTPGETNOLOADER } from "@/api";
import { mapGetters, mapMutations, mapActions } from "vuex";
export default {
  name: "Notifications",
  computed: {
    ...mapGetters([
      "NOTIFICATIONSLIST",
      "VISIBLENOTIFICATION",
      "RELOADCHAT",
      "getAuth"
    ]),
    auth() {
      return this.getAuth;
    }
  },
  watch: {
    auth() {
      if (!this.getAuth) {
        clearInterval(this.interval);
      }
    }
  },
  data: () => ({
    notifications: null,
    visibleNotification: true,
    interval: null,
    visibleHrefGo: true
  }),
  mounted() {
    this.getNotifications();
    this.update_notification();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  methods: {
    ...mapActions(["ADDPATH"]),
    ...mapMutations([
      "set_notifications_list",
      "set_visible_notifications",
      "get_reload_cahat",
      "set_check_repairs"
    ]),
    getNotifications() {
      HTTPGET({
        url: "notifications/get_notifications.php"
      }).then(response => {
        this.set_notifications_list(response.data.notification);
        if (response.data.checkRepairs) {
          this.set_check_repairs(response.data.checkRepairs);
        }
      });
    },
    update_notification() {
      this.interval = setInterval(() => {
        HTTPGETNOLOADER({
          url: "notifications/get_notifications.php"
        }).then(response => {
          this.set_notifications_list(response.data.notification);
          if (response.data.checkRepairs) {
            this.set_check_repairs(response.data.checkRepairs);
          }
        });
      }, 5000);
    },
    close() {
      this.set_visible_notifications(!this.VISIBLENOTIFICATION);
    },
    goRepair(type, typeOfRepair, confirm_act, href, id) {
      this.read(id, type);
      if (type === "ремонт") {
        if (typeOfRepair === "repair") {
          this.ADDPATH(`созданные ремонты`);
        }
        if (typeOfRepair === "noRepair" && confirm_act) {
          this.ADDPATH(`отказы от ремонта / подтвержденные`);
        }
        if (typeOfRepair === "noRepair" && !confirm_act) {
          this.ADDPATH(`отказы от ремонта / не подтвержденные`);
        }
        if (typeOfRepair === "diagnostiks") {
          this.ADDPATH(`ремонты без зч и диагностики`);
        }
        if (this.$route.path !== href) {
          this.$router.push(href);
        }
      } else {
        if (this.$route.path !== href) {
          this.$router.push(href);
        }
      }
    },
    read(id, type) {
      HTTPGET({
        url: "notifications/read_message.php",
        params: {
          id,
          type
        }
      })
        .then(() => this.getNotifications())
        .then(() => this.get_reload_cahat(true));
    }
  }
};
</script>
<style lang="scss" scoped>
.notifications {
  position: fixed;
  bottom: 5px;
  right: 5px;
  z-index: 1;
  max-height: calc(100vh - 60px);
  overflow: auto;
  background: #fff;
  padding-top: 10px;
}
.item,
.close-not {
  background: #c2c2c2;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  font-size: 0.8em;
}
.user {
  color: #007bff;
}
.message {
  border: 1px solid #fff;
  border-radius: 5px;
  padding: 3px;
  background: #fff;
  margin-top: 5px;
}
.btn-read {
  position: absolute;
  top: 1px;
  right: 1px;
  font-size: 0.7em;
}
</style>
