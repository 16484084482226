import { get } from "@/components/functions/number_format.js";
export const repairsNotificationsModule = {
  state: () => ({
    diagnostics: 0,
    acts: 0,
    repairs_pending_issue_count: 0,
    favorites: 0,
    consultations: 0
  }),
  mutations: {
    set_counts(state, data) {
      state.diagnostics = data.diagnostics;
      state.favorites = data.favorites;
      state.consultation = data.consultation;
      state.acts = data.acts;
      state.repairs_pending_issue_count = data.repairs_pending_issue_count;
    }
  },
  getters: {
    DIAGNOSTICS_COUNT: state => {
      return state.diagnostics;
    },
    ACTS_NO_CONFIRM_COUNT: state => {
      return state.acts;
    },
    ALL_COUNT: state => {
      return state.acts + state.diagnostics;
    },
    REPAIRS_PENDING_ISSUE_COUNT: state => {
      return state.repairs_pending_issue_count;
    },
    FAVORITES_REPAIRS_COUNT: state => {
      return state.favorites;
    },
    CONSULTATION_REPAIRS_COUNT: state => {
      return state.consultation;
    }
  },
  actions: {
    GET_REPAIRS_COUNTS({ commit }) {
      get("notifications/repairs_counts.php", commit, "set_counts");
    }
  }
};
