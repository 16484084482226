export const loaderModule = {
  state: () => ({
    loader: false,
    loader2: false,
    error: false
  }),
  mutations: {
    loaderOnOff(state, onoff) {
      state.loader = onoff;
    },
    LOAD(state, onoff) {
      state.loader2 = onoff;
    },
    errorOnOff(state, onoff) {
      state.error = onoff;
    }
  },
  actions: {
    LOADERONOFF({ commit }, onoff) {
      commit("loaderOnOff", onoff);
    },
    ERRORONOFF({ commit }, onoff) {
      commit("errorOnOff", onoff);
    }
  },
  getters: {
    LOADER: state => {
      return state.loader;
    },
    LOADER1: state => {
      return state.loader2;
    },
    ERROR: state => {
      return state.error;
    }
  }
};
