export const repairsPendingIssueListModule = {
  state: () => ({
    user: "all"
  }),
  mutations: {
    set_user_repairs_pending_issue_list(state, user) {
      state.user = user;
    }
  },
  actions: {},
  getters: {
    SELECTREPAIRSPENDINGISSUELISTUSER: state => {
      return state.user;
    }
  }
};
