export const repairsSelectModule = {
  state: () => ({
    user: "all"
  }),
  mutations: {
    set_user(state, user) {
      state.user = user;
    }
  },
  actions: {},
  getters: {
    SELECTREPAIRSUSER: state => {
      return state.user;
    }
  }
};
