export const reportsListFilterModule = {
  state: () => ({
    filter: {
      period_start: "01",
      period_end: "12"
    }
  }),
  mutations: {
    set_period_start(state, data) {
      state.filter.period_start = data;
    },

    set_period_end(state, data) {
      state.filter.period_end = data;
    }
  },
  actions: {},
  getters: {
    REPORTSFILTER: state => {
      return state.filter;
    }
  }
};
