export const userZapModule = {
  state: () => ({
    userZapList: [],
    userZapOrder: [],
    oldUserZapOrder: [],
    zapList: []
  }),
  mutations: {
    oldZap(state, data) {
      state.oldUserZapOrder = data;
    },
    setUserZap(state, data) {
      state.userZapList = data;
    },
    setUserZapOrder(state, data) {
      state.userZapOrder = data;
    },
    addInOrderUserZap(state, data) {
      let add = 0;
      if (state.userZapOrder.length) {
        state.userZapOrder.map(item => {
          if (item.cod === data.cod) {
            item.pcs = item.pcs + 1;
            add = add + 1;
          }
        });
        if (add === 0) {
          state.userZapOrder.push(data);
        }
      } else {
        state.userZapOrder.push(data);
      }
    },
    DeleteInOrderUserZap(state, idx) {
      state.userZapOrder.splice(idx, 1);
    },
    userZapOrderNulll(state) {
      state.userZapOrder = [];
    },
    addZap(state, data) {
      let search = 0;
      state.zapList.map(item => {
        if (item.cod === data.cod) {
          search++;
          item.pcs++;
        }
      });
      if (search === 0) {
        state.zapList.push(data);
      }
    },
    deleteZap2(state, idx) {
      state.zapList.splice(idx, 1);
    },
    zapNull(state) {
      state.zapList = [];
    },
    zap(state, data) {
      if (data) {
        state.zapList = data;
      }
    },
    userZapListNull(state) {
      state.userZapList = [];
    }
  },
  actions: {},
  getters: {
    USERZAP: state => {
      return state.userZapList;
    },
    USERZAPORDER: state => {
      return state.userZapOrder;
    },
    OLDUSERZAPORDER: state => {
      return state.oldUserZapOrder;
    },
    ZAP: state => {
      return state.zapList;
    }
  }
};
