import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";
import { pathModule } from "./pathModule";
import { zapModule } from "./zapModule";
import { loaderModule } from "./loaderModule";
import { userInfoModule } from "./userInfoModule";
import { notificationsModule } from "./notificationsModule";
import { userZapModule } from "./userZapModule";
import { routerModule } from "./routerModule";
import { reportsDateModule } from "./reportsDateModule";
import { actsmodule } from "./actsmodule";
import { repairsNotificationsModule } from "./repairsNotificationsModule";
import { repairsListAllModule } from "./repairsListAllModule";
import { repairsSelectModule } from "./repairsSelectModule";
import { ordersListModule } from "./ordersListModule";
import { statsModule } from "./statsModule";
import { reportsListFilterModule } from "./reportsListFilter";
import { repairsPendingIssueListModule } from "./repairsPendingIssueListModule";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    userInfoModule,
    pathModule,
    zapModule,
    loaderModule,
    notificationsModule,
    userZapModule,
    routerModule,
    reportsDateModule,
    actsmodule,
    repairsNotificationsModule,
    repairsListAllModule,
    repairsSelectModule,
    ordersListModule,
    statsModule,
    reportsListFilterModule,
    repairsPendingIssueListModule
  },
  plugins: [
    createPersistedState({
      paths: [
        "userInfoModule.userInfo",
        "routerModule.pathTo",
        "routerModule.pathFrom",
        "pathModule.path",
        "pathModule.pathRepair",
        "reportsDateModule.date",
        "repairsNotificationsModule",
        "statsModule"
      ]
    })
  ]
});
