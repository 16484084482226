export const routerModule = {
  state: () => ({
    pathTo: "/home",
    pathFrom: ""
  }),
  mutations: {
    setPathTo(state, path) {
      if (path === "/login") {
        path = "/home";
      } else {
        state.pathTo = path;
      }
    },
    setPathFrom(state, path) {
      state.pathFrom = path;
    }
  },
  actions: {
    PATH_TO({ commit }, path) {
      commit("setPathTo", path);
    },
    PATH_FROM({ commit }, path) {
      commit("setPathFrom", path);
    }
  },
  getters: {
    GET_PATH_TO: state => {
      return state.pathTo;
    },
    GET_PATH_FROM: state => {
      return state.pathFrom;
    }
  }
};
