import axios from "axios";
import store from "@/store";
import u from "@/constants/";

const BASEURL = u.url1;
//const BASEURL = "/api/";
export const HTTPGETNOLOADERNOCOOCIE = axios.create({
  method: "get",
  baseURL: BASEURL
});
HTTPGETNOLOADERNOCOOCIE.interceptors.request.use(res => {
  store.dispatch("LOADERONOFF", false);
  return res;
});
HTTPGETNOLOADERNOCOOCIE.interceptors.response.use(
  res => {
    store.dispatch("LOADERONOFF", false);
    return res;
  },
  function(error) {
    store.dispatch("ERRORONOFF", true);
    store.dispatch("LOADERONOFF", false);
    return Promise.reject(error);
  }
);

export const HTTPGETNOLOADER = axios.create({
  method: "get",
  baseURL: BASEURL,
  withCredentials: true
});
HTTPGETNOLOADER.interceptors.request.use(res => {
  store.dispatch("LOADERONOFF", false);
  return res;
});
HTTPGETNOLOADER.interceptors.response.use(
  res => {
    store.dispatch("LOADERONOFF", false);
    return res;
  },
  function(error) {
    store.dispatch("ERRORONOFF", true);
    store.dispatch("LOADERONOFF", false);
    return Promise.reject(error);
  }
);

export const HTTPGET = axios.create({
  method: "get",
  baseURL: BASEURL,
  withCredentials: true,
  onDownloadProgress: function(progressEvent) {
    let percent = parseInt(
      Math.round(progressEvent.loaded / progressEvent.total) * 100
    );
    if (!progressEvent.total) {
      store.dispatch("LOADERONOFF", false);
      return;
    }
    if (percent === 100) {
      store.dispatch("LOADERONOFF", false);
    } else {
      store.dispatch("LOADERONOFF", true);
    }
  }
});
HTTPGET.interceptors.request.use(res => {
  store.dispatch("LOADERONOFF", true);
  axios({
    url: "/admin/log/requests.php",
    params: {
      url: res.url,
      params: res.params
    },
    baseURL: BASEURL,
    withCredentials: true
  });
  return res;
});
HTTPGET.interceptors.response.use(
  res => {
    store.dispatch("LOADERONOFF", false);
    return res;
  },
  function(error) {
    store.dispatch("ERRORONOFF", true);
    store.dispatch("LOADERONOFF", false);
    return Promise.reject(error);
  }
);
export const HTTPPOSTFILE = axios.create({
  method: "post",
  baseURL: BASEURL,
  withCredentials: true,
  headers: {
    "Content-Type": "multipart/form-data"
  }
});
HTTPPOSTFILE.interceptors.request.use(res => {
  store.dispatch("LOADERONOFF", true);
  return res;
});
HTTPPOSTFILE.interceptors.response.use(
  res => {
    store.dispatch("LOADERONOFF", false);
    return res;
  },
  function(error) {
    store.dispatch("ERRORONOFF", true);
    store.dispatch("LOADERONOFF", false);
    return Promise.reject(error);
  }
);
export const HTTPPOST = axios.create({
  method: "post",
  baseURL: BASEURL,
  withCredentials: true
});
HTTPPOST.interceptors.request.use(res => {
  store.dispatch("LOADERONOFF", true);
  return res;
});
HTTPPOST.interceptors.response.use(
  res => {
    store.dispatch("LOADERONOFF", false);
    return res;
  },
  function(error) {
    store.dispatch("ERRORONOFF", true);
    store.dispatch("LOADERONOFF", false);
    return Promise.reject(error);
  }
);
