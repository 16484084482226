<template>
  <div class="wrapper text-center" v-if="ERROR">
    <div class="alert alert-warning col-md-4">
      <div>что-то пошло не так</div>
      <div>
        <button class="btn btn-sm btn-primary shadow-none" @click="close">
          закрыть
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  name: "Error",
  computed: { ...mapGetters(["ERROR"]) },
  methods: {
    ...mapActions(["ERRORONOFF"]),
    close() {
      this.ERRORONOFF(false);
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  z-index: 10000;
  position: fixed;
  top: 10px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.btn {
  padding: 3px;
  margin-top: 10px;
}
</style>
