import { HTTPGET } from "@/api";
export const zapModule = {
  state: () => ({
    zap: [],
    downloadPercentage: "",
    placeholder: "",
    loading: true,
    order_count: 100,
    filter_order_text: ""
  }),
  mutations: {
    set_zap(state, data) {
      state.zap = data;
    },
    set_zap_download_percent(state, data) {
      state.downloadPercentage = data;
    },
    set_zap_download_placeholder(state, data) {
      state.placeholder = data;
    },
    set_zap_download_loading(state, data) {
      state.loading = data;
    },
    set_zap_order_count(state, data) {
      state.order_count = data;
    },
    set_zap_filter_order_text(state, data) {
      state.filter_order_text = data;
    }
  },
  actions: {
    GET_ZAP({ commit }, data = null) {
      return new Promise((resolve, reject) => {
        HTTPGET({
          url: "get_zap/get_zap.php",
          params: data,
          onDownloadProgress: function(progressEvent) {
            if (progressEvent.total > 0) {
              let downloadPercentage = parseInt(
                Math.round((progressEvent.loaded * 100) / progressEvent.total)
              );
              commit("set_zap_download_percent", downloadPercentage);
              commit("set_zap_download_placeholder", "загрузка данных... ");
              commit("set_zap_download_loading", true);
            }
          }
        })
          .then(resp => {
            commit("set_zap", resp.data);
            resolve(resp.data);
          })
          .then(() => {
            commit("set_zap_download_percent", "");
            commit(
              "set_zap_download_placeholder",
              "поиск запчастей для ремонта (по артикулу или наименованию)"
            );
            commit("set_zap_download_loading", false);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    }
  },
  getters: {
    LIST_ZAP: state => {
      return state.zap;
    },
    DOWNLOADPERCENTAGE: state => {
      return state.downloadPercentage;
    },
    PLACEHOLDER: state => {
      return state.placeholder;
    },
    ZAPLOADING: state => {
      return state.loading;
    },
    ZAPORDERCOUNT: state => {
      return state.order_count;
    },
    ZAPOFILTERORDERTEXT: state => {
      return state.filter_order_text;
    }
  }
};
