import { HTTPGET, HTTPPOST, HTTPGETNOLOADER } from "@/api";
export const userInfoModule = {
  state: {
    userInfo: {
      auth: false,
      reports: false,
      status: null,
      user_name: null,
      error: null,
      closeSite: false
    }
  },
  mutations: {
    auth_request(state) {
      state.userInfo.error = null;
    },
    auth_success(state, userInfo) {
      state.userInfo = userInfo;
    },
    change_user(state, userInfo) {
      state.userInfo.status = userInfo.status;
      state.userInfo.reports = userInfo.reports;
      state.userInfo.user_name = userInfo.user_name;
    },
    auth_error(state) {
      state.userInfo.error = "что-то пошло не так";
    },
    logout(state) {
      state.userInfo.auth = false;
    },
    loader(state, resp) {
      state.userInfo.error = resp.error;
    },
    closeSite(state, onoff) {
      state.userInfo.closeSite = onoff;
    }
  },
  getters: {
    getAuth: state => {
      return state.userInfo.auth;
    },
    AUTH_ERROR: state => {
      return state.userInfo.error;
    },
    CHANGE_USER_MENU_VISIBLE: state => {
      return state.userInfo.change_user;
    },
    REPORTS: state => {
      return state.userInfo.reports;
    },
    USER_NAME: state => {
      return state.userInfo.user_name;
    },
    USER_INFO: state => {
      return state.userInfo;
    },
    CLOSESITE: state => {
      return state.userInfo.closeSite;
    }
  },
  actions: {
    LOGIN({ commit }, user) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTPPOST({ url: "auth/auth.php", data: user })
          .then(resp => {
            if (resp.data.response.auth) {
              commit("auth_success", resp.data.response);
              resolve(resp.data.response);
            } else {
              commit("loader", resp.data.response);
            }
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    CHECK_AUTH({ commit }) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTPGET("auth/auth.php")
          .then(resp => {
            const userInfo = resp.data.response;
            commit("auth_success", userInfo);
            resolve(resp.data);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    CHECK_AUTH_NO_LOADER({ commit }) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTPGETNOLOADER("auth/auth.php")
          .then(resp => {
            const userInfo = resp.data.response;
            commit("auth_success", userInfo);
            resolve(resp.data);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    LOGOUT({ commit }) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTPGET("logout/")
          .then(resp => {
            commit("logout");
            resolve(resp.data);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    },
    CHANGE_USER({ commit }, id) {
      return new Promise((resolve, reject) => {
        commit("auth_request");
        HTTPGET(`home/change_user/change_user.php?id=${id}`)
          .then(resp => {
            commit("change_user", resp.data);
            resolve(resp.data);
          })
          .catch(err => {
            commit("auth_error");
            reject(err);
          });
      });
    }
  }
};
