<template>
  <div id="app">
    <div
      v-if="CLOSESITE && USER_INFO.status !== 'admin' && !USER_INFO.change_user"
    >
      <CloseSites />
      <Login />
    </div>
    <div v-else>
      <Loader v-if="LOADER || LOADER1" />
      <div v-else />
      <component :is="layout">
        <router-view />
      </component>
    </div>
    <Error />
  </div>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import EmptyLayout from "@/layouts/EmptyLayout";
import Loader from "@/components/Loader";
import CloseSites from "@/components/CloseSite";
import Login from "@/views/Login.vue";
import Error from "@/components/Error.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "empty") + "-layout";
    },
    ...mapGetters([
      "getAuth",
      "CLOSESITE",
      "USER_INFO",
      "LOADER",
      "LOADER1",
      "ERROR"
    ])
  },
  data: () => ({
    interval: null
  }),
  methods: {
    ...mapActions(["CHECK_AUTH", "ERRORONOFF", "CHECK_AUTH_NO_LOADER"])
  },
  components: {
    MainLayout,
    EmptyLayout,
    Loader,
    CloseSites,
    Login,
    Error
  },
  mounted() {
    this.CHECK_AUTH();
    setTimeout(() => {
      if (!this.getAuth && this.$route.meta.auth) {
        if (this.$route.path !== "/login") this.$router.push("/login");
      }
    }, 2000);
    this.interval = setInterval(() => {
      this.CHECK_AUTH_NO_LOADER();
      if (!this.getAuth && this.$route.meta.auth) {
        console.log(this.$route);
        if (this.$route.path !== "/login") this.$router.push("/login");
        this.interval = clearInterval(this.interval);
      }
    }, 5000);
  }
};
</script>
<style lang="scss">
body {
  padding-top: constant(safe-area-inset-top);

  padding-top: env(safe-area-inset-top);
}
.table thead th td {
  vertical-align: middle;
}
.table_response {
  overflow-x: auto;
}
</style>
