export const statsModule = {
  state: () => ({
    filter: {
      year: new Date().getFullYear(),
      other: [
        { field: "name_instr", value: "" },
        { field: "serialNumberGroup", value: "" },
        { field: "gruppa", value: "" },
        { field: "cod", value: "" },
        { field: "stats_prodaji.pcs", value: "", type: "number" }
      ],
      sort: { field: "name_instr", sort: true }
    },
    visible_filter: false,
    apply_btn_disable: false,
    scroll: 0,
    scroll2: 0,
    exclude_working: true,
    select_options: "all",
    url_stats: "/stats/data_stats/get_data_stats_2.php"
  }),
  mutations: {
    set_stats_filter(state, data) {
      state.filter.other[data.field] = data.value;
    },
    set_visible_stats_filter(state) {
      state.visible_filter = !state.visible_filter;
    },
    set_filter_apply_btn_disable(state, value) {
      state.apply_btn_disable = value;
    },
    set_stats_sort(state, data) {
      state.filter.sort.field = data.field;
      state.filter.sort.sort = data.value;
    },
    set_stats_page_scroll(state, data) {
      state.scroll = data;
    },
    set_stats_page_scroll2(state, data) {
      state.scroll2 = data;
    },
    set_stats_exclude_working(state, data) {
      state.exclude_working = data;
    },
    set_stats_select_options(state, data) {
      state.select_options = data;
    },
    set_stats_url(state, data) {
      state.url_stats = data;
    }
  },
  actions: {},
  getters: {
    STATS_FILTER: state => {
      return state.filter;
    },
    STATS_VISIBLE_FILTER: state => {
      return state.visible_filter;
    },
    STATS_APPLY_BTN_DISABLE: state => {
      return state.apply_btn_disable;
    },
    STATS_PAGE_SCROLL: state => {
      return state.scroll;
    },
    STATS_PAGE_SCROLL2: state => {
      return state.scroll2;
    },
    STATS_EXCLUDE_WORKING: state => {
      return state.exclude_working;
    },
    STATS_SELECT_OPTIONS: state => {
      return state.select_options;
    },
    STATS_URL: state => {
      return state.url_stats;
    }
  }
};
