export const notificationsModule = {
  state: () => ({
    visibleNotification: true,
    notificationsList: [],
    reloadChat: false,
    checkRepairs: null
  }),
  mutations: {
    set_notifications_list(state, data) {
      state.notificationsList = data;
    },
    set_visible_notifications(state, data) {
      state.visibleNotification = data;
    },
    get_reload_cahat(state, data) {
      state.reloadChat = data;
    },
    set_check_repairs(state, data) {
      state.checkRepairs = data;
    }
  },
  actions: {},
  getters: {
    VISIBLENOTIFICATION: state => {
      return state.visibleNotification;
    },
    NOTIFICATIONSLIST: state => {
      return state.notificationsList;
    },
    RELOADCHAT: state => {
      return state.reloadChat;
    },
    GET_CHECK_REPAIRS: state => {
      return state.checkRepairs;
    }
  }
};
