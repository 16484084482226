<template>
  <div>
    <img :src="`${url}img/close.png`" alt="закрыто" />
  </div>
</template>
<script>
import u from "@/constants";
export default {
  name: "CloseSites",
  data: () => ({
    url: ""
  }),
  mounted() {
    this.url = u.url1;
  }
};
</script>
<style lang="scss" scoped>
img {
  display: block;
  margin: 10px auto;
}
</style>
