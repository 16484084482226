export const actsmodule = {
  state: () => ({
    confirm: false,
    pagination: {
      start: null,
      end: null,
      pagination: null,
      count_rows: null,
      pages: null,
      showStr: 20,
      page: 1,
      filter: {}
    }
  }),
  mutations: {
    confirmOrNotConfirmActs(state) {
      state.confirm = !state.confirm;
    },
    setStart(state, start) {
      state.pagination.start = start;
    },
    setPagination(state, pagination) {
      state.pagination = pagination;
    },
    setEnd(state, end) {
      state.pagination.end = end;
    },
    setShowStr(state, showStr) {
      state.pagination.showStr = showStr;
    },
    setPages(state, pages) {
      state.pagination.pages = pages;
    },
    setPage(state, page) {
      state.pagination.page = page;
    },
    setCountRows(state, count_rows) {
      state.pagination.count_rows = count_rows;
    },
    delFilter(state) {
      state.pagination.filter = {};
    },
    setFilter(state, name) {
      state.pagination.filter[name.name] = name.value;
      if (state.pagination.filter[name.name].length < 1) {
        delete state.pagination.filter[name.name];
      }
    }
  },
  actions: {},
  getters: {
    CONFIRM_OR_NOT_CONFIRM_ACTS: state => {
      return state.confirm;
    },
    PAGINATION: state => {
      return state.pagination;
    }
  }
};
